@import "~styles/_common.module.scss";
.selected-bar-details {
  display: flex;
  border: 1px solid $medium-grey;
  border-radius: 0.1rem;
  padding: 0.4rem 0.2rem;
  background: $white;
  justify-content: space-between;
  &__details {
    text-align: center;
    flex-grow: 1;
    span {
      text-transform: capitalize;
    }
  }
  &__icon.icon-close {
    @include size-above(md) {
      display: none;
    }
  }
}

svg foreignObject.details {
  box-shadow: 1rem 1rem 1rem 0 rgba(0, 0, 0, 0.1);
}
