@import "~styles/_common.module.scss";

.waterfallchart-ivcr-chart {
  position: relative;
  border: 1px solid $medium-grey;
  border-radius: 0.5rem;
  .--hidden {
    display: none;
  }
  &.--no-data-overlay{
    background-color: rgba($pale-grey,.32);
  }
}
.waterfallchart-ivcr {
  width: 100%;
  padding: 0.5rem 2rem;
  position: relative;
  overflow: auto;
  min-height:30rem;
  &.--has-overlay {
    opacity: 0.1;
    .chart__shadow{
      &:before,
      &:after{
        background-color: transparent;
        background-image: none;
      }
    }
  }

  &:not(.--has-overlay) {
    opacity: 1;
  }

  &__loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    background: white;
  }

  &__no-data {
    width: 20%;
    height: auto;
    max-width: 10rem;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    z-index: 1;
  }

  .--hidden {
    display: none;
  }

  .y-label {
    transform: rotate(270deg);
    transform-origin: left;
    position: absolute;
    top: 200px;
    left: 50px;
    direction: rtl;
    width: 10rem;
    @include size-below(lg) {
      top: 15rem;
      left: 1.5rem;
    }
  }

  svg {
    display: block;
    min-height: 30rem;
    margin-right: 1rem;
    padding: 2rem 3rem 0;
    @include size-below(lg) {
      padding: 1rem 1rem 0;
    }
    @include size-below(md){
      min-height: 42rem;
    }
    .tick:first-child > line {
      stroke: $charcoal;
    }
  }

  .plan-tile {
    &.background-tile:not(.selected-bar) {
      fill: transparent;
    }
  }
  .cost-tile {
    &.background-tile:not(.selected-bar) {
      fill: transparent;
    }
  }
  .actual-tile {
    &.background-tile:not(.selected-bar) {
      fill: transparent;
    }
  }
  .negative-tile {
    fill: $pale-grey;
  }
}
