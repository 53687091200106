@import "~styles/_common.module.scss";

.cmp-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;

  &.bordered {
    border-width: 1px;
    border-style: solid;
    border-color: $medium-grey;
    border-image: initial;
    border-radius: $border-radius;
  }
}
