@import "~styles/_common.module.scss";

.accordion-table {
  &.table-holder {
    display: flex;
    justify-content: center;
    border: 1px solid $medium-grey;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
    background-color: $white;
    .table {
      width: 100%;
      table-layout: fixed;

      @include size-below(md) {
        //display: block;
        overflow: scroll;
      }
      @include size-below(md) {
        font-size: $font-md;
      }
      border-collapse: collapse;
      &__header{
        font-weight: bold;
        color: $charcoal;
        border-bottom: 2px solid $medium-grey;
      }
      
      &__footer{
        color: $grey;
        font-weight: normal;
      }

        &__header,
        &__footer {
        font-size: $font-md;
        padding: 1.125rem 1rem;
        user-select: none;
        text-transform: uppercase;
        width: 15%;
        @include size-below(md) {
          font-size: $font-sm;
        }
        text-align: right;
        &:first-child {
          width:40%;
          text-align: left;
          padding-left: $table-padding;
        }
        &:last-child{
          padding-right: $table-padding;
          width: auto;
        }
        i {
          color: $charcoal;
          margin-left: 0.5rem;
          margin-bottom: 0.1rem;
          cursor: pointer;
        }
        p {
          margin: 0;
          white-space: nowrap;
        }
      }
      tbody {
        tr {
          &:not(:last-child){
            cursor: pointer;
            &:hover {
              background-color: $off-white;
            }
          }
        }
      }
      &__body {
        color: $grey;
        border: none;
        text-align: right;
        padding: 1.2rem;
        font-size: $font-md;
        font-weight: 500;
        line-height: 1.1rem;
        width: auto;

        @include size-below(md) {
          font-size: $font-md;
        }

        &:first-child {
          text-align: left;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }

        .positiveDeviation {
          color: $teal;
        }
        .minus {
          color: $tomato;
          .indicator.up > i{
            transform: rotate(180deg);
          }
          &.opp {
            color: $teal;
          }
        }
        .plus {
          color: $teal;
          &.opp {
            color: $tomato;
            .indicator.down > i{
              transform: rotate(180deg);
            }
          }
        }

        i {
          padding-right: 0.4rem;
          &.icon-table-arrow_right {
            cursor: pointer;
          }
        }

        &:nth-child(2) {
          @include size-below(md) {
            white-space: pre;
          }
        }

        &:nth-child(1) {
          width: 100%;
          padding-left: $table-padding;
        }
        &:last-child{
          padding-right: $table-padding;
        }
        .name-attr {
          text-transform: capitalize;
        }
      }
      tr {
        font-weight: 400;
        border-bottom: 1px solid $medium-grey;
        &.expanded {
          &:hover {
            background-color: transparent;
          }
          .table__body {
            color: $charcoal;
            font-weight: bold;
          }
          .name-attr {
            font-weight: bold;
          }
        }

        &.child-row {
          background-color: $pale-grey;
          border-bottom: 1px solid $medium-grey;
          &:hover {
            background-color: $pale-grey;
          }
          .table__body > .name-attr {
            padding-left: 3rem;
          }
          &.first-child {
            border-top: 4px solid $teal;
          }
          &.sub-child{
            .table__body > .name-attr {
              padding-left: 4.5rem;
              &:before {
                content:"-";
                display:inline-block;
                position:absolute;
                transform: translateX(-1rem);
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
          span {
            display: inline-block;
          }
        }
        &:last-child {
          border-bottom: none;
          td:first-child {
            border-bottom-left-radius: $border-radius;
          }
          td:last-child {
            border-bottom-right-radius: $border-radius;
          }
        }
        .right-align {
          text-align: right;
          .indicator.up > i{
            font-size: $font-lg;
            vertical-align: top;
          }
          .indicator.down > i{
            font-size: $font-lg;
          }
          .indicator.disabled > i{
            font-size: $font-lg;
          }
        }
        p {
          margin: 0;
          white-space: nowrap;
        }
      }
    }
  }
}
