@import "~styles/_common.module.scss";

.legend {
  display: flex;
  flex-direction: column;
  margin-right: 0.8rem;
  &__left {
    color: $grey;
    padding-bottom: 0.5rem;
  }

  &__right {
    display: flex;
    border: 0.01rem solid $medium-grey;
    padding: 0.8rem 0.5rem;
    padding-left: 1.4rem;
  }
}
