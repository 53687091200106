@import "~styles/_common.module.scss";

$card-unitvalue-fontsize: 26;
$card-trend-fontsize: 12;

.cmp-card {
  background-color: $white;
  color: $grey;
  justify-content: center;
  text-align: center;

  &.bordered {
    padding: 3px;
    @include size-above(md) {
      padding: 12px;
    }
    &.no-padding {
      padding: 0;
    }
  }

  &.grey-tile {
    background: $pale-grey;
    color: #fff;

    .cmp-card {
      background: $pale-grey;
    }
  }

  h1.card-title--header {  
    &.spinner {
      height: $font-lg;
    }
  }

  .dashboard-headerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .trend-and-unit {
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    height: 4rem;
    align-items: center;
    justify-content: flex-end;
    .trend {
      .indicator.up {
        > i {
          vertical-align: text-top;
        }
      }
    }
  }

  dt,
  dd {
    margin-bottom: 12px/2;
    @include size-between(sm, md) {
      margin-bottom: 12px/4;
    }
  }
  //Organisms
  .unit-and-trend {
    display: flex;
    flex-direction: column;
    .variance-body {
      display: flex;
      width: auto;
      // Removed: Was causing issues with alignment of icons.
      // height: 4rem;
      padding-top: ($card-trend-fontsize + ($gutter/1px)/4) + px;
      .variance-body-indicator-positions {
        display: flex;
        flex-direction: column;
      }
      &.spinner-height {
        padding-bottom: 4.6rem; //This is just to align the spinner height to be the same
      }
    }
    .indicator {
      &.overview-chevron-icon {
        display: flex;
        align-items: center;
        font-size: 3rem;
        margin-right: 0.5rem;
        @include size-below(md) {
          margin-right: 0.1rem;
        }
        i {
          &:before {
            font-size: 1.75rem;
            margin-top: -0.5rem;
          }
        }
      }
    }
    &.spinner {
      height: 1rem;
      width: 4rem;
      display: inline-table;
    }
  }
  .card-group {
    &.border-top {
      border-top: 1px solid $pale-grey;
    }
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    .cmp-card.item {
      align-items: center;
      width: 50%;
      flex: 0 1 auto;
      border-right: 1px solid $pale-grey;
      padding: 0;
      align-self: flex-start;

      @include size-above(md) {
        padding: $card-with-no-icon-padding 0;
      }
      margin: 0 1px;

      &:last-child {
        border-right: none;
        &.spinner {
          margin-left: 1rem;
        }
      }
    }
  }
}
