@import "~styles/_common.module.scss";

.ccng-filters {
  transition: all 200ms ease-in-out;
  margin-bottom: $filter-margin-bottom;
  background-color: transparent;
  padding: 0.4rem 0;
  &.sticky {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4rem 1rem 0px;
    border-radius: 0 0 0.2rem 0.2rem;
    background-color: white;
    // -2 because parent has a margin right and left of 2.
    padding: 0.4rem 2rem;
    margin: 0 -2rem 1.2rem -2rem;
    @include size-below(md) {
      margin: 0 -0.5rem;
      padding: 0 0.5rem;
    }
  }

  .ccng-filters-container {
    .spaced {
      margin: 0.25rem 0;
      margin-right: $filter-margin-right;
    }

    .filter-container__left__child {
      flex-wrap: wrap;
    }
  }
}

.ccng-Overview-header-with-subtitles {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.homepage__article__tab-content__container.ccng__corporate-cost {
  justify-content: flex-start;
  margin: 0;
  padding: 0.5rem 0 1.75rem 0;
}

.homepage__footer.ccng__overview__footer {
  border-top: 1px solid $medium-grey;
  margin: 2.5rem 0 0 0;
  .ccng__overview__footer__cards {
    &__floorPrices {
      &__priceDifference {
        display: flex;
        flex-direction: row;
        color: $grey;
        margin-top: 2.5rem;

        h2 {
          flex-grow: 1;
          margin-bottom: 0.4375rem;
          margin-top: 1.25rem;
        }
        > .spaced {
          margin-left: 1rem;
        }
        + .sortable-table.table-holder .table__header:first-child {
          width: 30%;
        }
      }
    }
    @include size-above(md) {
      &__floorPrices {
        margin-right: 0.8rem;
        flex-basis: 48%;
      }
      &__market {
        flex-basis: 48%;
      }
    }

    @include size-below(md) {
      &__floorPrices {
        flex-basis: 100%;
      }
      &__market {
        flex-basis: 100%;
      }
    }
    .table {
      width: 100%;
      tr {
        font-weight: 400;
      }
      &__body {
        text-align: right;
        &:first-child {
          text-align: left;
        }
        color: #666769;
        border: none;
        padding: 1.2rem;
        font-size: 1.2rem;
        font-weight: 500;

        .currency-wrapper {
          display: inline-flex;
          .super-currency {
            font-size: 0.75rem;
            font-weight: 300;
            padding-right: 0.2rem;
            padding-left: 0.4rem;
            vertical-align: text-top;
          }
        }
      }
      &__header {
        text-align: right;
        padding: 1.125rem 1rem;
        &:first-child {
          text-align: left;
        }
      }
    }
  }
}
.homepage__article__tab-content__sub-segment__buttons > button {
  align-items: center;
}
.homepage__article__tab-content__sub-segment__buttons--view-less {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
}
.homepage__article__tab-content__sub-segment__viewAll {
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  color: $teal;
  margin: 1.0625rem 0 0 0;
}
.ccng-contributors-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ccng-entity-dropdwn > div > ul {
    right: 0;
    left: auto;
    position: absolute;
  }
}
.super-currency {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 300;
  padding-left: 0.2rem;
  vertical-align: text-top;
}
.value {
  display: inline-block;
  line-height: 1;
  padding-left: 0.2rem;
}

.homepage__article__tab-content__container__group.ccng__contributor__group.CCNG__integrated {
  .no-visible {
    visibility: hidden;
    height: 0.5rem;
  }
}
