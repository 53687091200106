@import "~styles/_common.module.scss";
.SRMC-filters-container {
  .spaced {
    margin: 0.25rem 0;
    margin-right: $filter-margin-right;
  }
}

.SRMC-header-with-subtitles {
  margin-top: 1rem;
  margin-bottom: 1.1rem;
  &_chart {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

.SRMC-filters {
  transition: all 200ms ease-in-out;
  margin-bottom: $filter-margin-bottom;
  background: transparent;
  padding: 0.4rem 0;
  &.sticky {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4rem 1rem 0px;
    border-radius: 0 0 0.2rem 0.2rem;
    background: white;
    // -2 because parent has a margin right and left of 2.
    // It's a hack. I had to. Sorry :/
    // If you have a better solution, please go ahead.
    // I was under pressure from other "chefs"
    padding: 0.4rem 2rem;
    margin: 0 -2rem 1.2rem -2rem;
    @include size-below(md) {
      margin: 0 -0.5rem;
      padding: 0 0.5rem;
    }
  }
}

.srmc__table {
  margin-top: 1.5rem;
}

.cost-margin .buildup-chart-legends.SRMC-legends {
  margin: 1rem 0 0;
}
.SRMC_chart_note {
  margin: 0 0 1rem 1rem;
  padding: 0 1rem;
}
