@import "~styles/_common.module.scss";

.buildupchart-ivcr {
  border: 1px solid $medium-grey;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  // Added a fixed height because the loading bar was not showing for some reason.
  min-height: 30rem;
  margin-bottom: 1rem;
  &.--no-data-overlay {
    background-color: rgba($pale-grey, 0.32);
  }

  &__loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    background: white;
  }

  &__no-data {
    width: 20%;
    height: auto;
    max-width: 10rem;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    z-index: 1;
  }

  .build-up-chart {
    height: 100%;

    &.--has-overlay {
      opacity: 0.1;
      height: 100%;
    }

    &:not(.--has-overlay) {
      opacity: 1;
    }
  }

  .--hidden {
    display: none;
  }
  .cost-tile {
    fill: $teal;
    &.background-tile:not(.selected-bar) {
      fill: transparent;
    }
  }
  .negative-cost-tile {
    fill: $cranberry;
    &.background-tile:not(.selected-bar) {
      fill: transparent;
    }
  }

  .margin-tile {
    fill: $sand;
    &.background-tile:not(.selected-bar) {
      fill: transparent;
    }
  }
  .revenue-tile {
    fill: $indigo;
    &.background-tile:not(.selected-bar) {
      fill: transparent;
    }
  }
  .marketPrices {
    cursor: pointer;
  }
  .negative-tile {
    fill: $pale-grey;
  }
  .error_message_chart {
    width: 50%;
    position: absolute;
    text-align: center;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
svg text.buildup-currency-text {
  dominant-baseline: hanging;
  tspan {
    dominant-baseline: hanging;
  }
}
