@import "~styles/_common.module.scss";
.trail-ivcr{
  // Added a fixed height because the loading bar was not showing for some reason.
  min-height: 30rem;
  
  &.--no-data-overlay{
    background-color: rgba($pale-grey,.32);
  }

  &__loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    background: white;
  }

  &__no-data {
    width: 20%;
    height: auto;
    max-width: 10rem;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    z-index: 1;
  }

  .trail-chart {
    height: 100%;
    &.--has-overlay{
      opacity: 0.1;
      height: 100%;
    }

    &:not(.--has-overlay){
      opacity: 1;
    }
  }

}

.xguide .tick text {
  transform: translate(8px, -12px);
}

.yguide .tick text {
  transform: translate(20px, -8px);
}

.xguide .tick,
.yguide .tick {
  &:first-child text {
    display: none;
  }
}

.tooltip {
  color: #fff;
  position: absolute;
  text-align: center;
  padding: 5px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}

.trail-hidden {
  .trail-circles,
  .trend-path {
    display: none;
  }
}

.Xline {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
}

//To animate
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
