@import "~styles/_common.module.scss";

.dropdown__options__menuParent{
    &__subOption {
        padding: 0.8rem;
        font-size: $font-md;
        
        &:hover {
          background-color: $pale-grey;
          cursor: pointer;
          font-weight: 700;
        }
  
        &.selected {
          color: $teal;
          font-weight: 700;
        }
      }
}