@import "~styles/_common.module.scss";

.CCNGValueLevers {
  &__chart {
    padding-bottom: 5rem;
  }

  &__cost-detail {
    padding-top: 2rem;
  }

  &__tables {
    border-top: 1px solid $medium-grey;

    &__heading {
      padding: 2rem 0 1rem 0;
      align-items: center;
      display: flex;

      .header-div {
        margin-right: auto;
      }

      .cost-dropdwn {
        min-width: 18rem;
        max-height: 4rem;
      }
    }

    &__sortable > .table > tbody > tr > td > .diffpercent > .indicator > .icon {
      padding-right: 0.4rem;
    }

    .fixed-cost {
      margin-bottom: 5rem;
    }

    // Uncomment this line if dancing of accordion tables repeats
    .accordion-table.table-holder .table__body:nth-child(1) {
     // max-width: 15rem;
    }

    .table__body > .name-attr {
      text-transform: capitalize;
    }
  }
}

.top-lever {
  padding-bottom: 4rem;
  border-top: 1px solid $medium-grey;
}

.value-levers-filters {
  transition: all 200ms ease-in-out;
  margin-bottom: $filter-margin-bottom;
  background: white;
  padding:0.4rem 0;
  &.sticky {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4rem 1rem 0px;
    border-radius: 0 0 0.2rem 0.2rem;
    // -2 because parent has a margin right and left of 2.
    padding: 0.4rem 2rem;
    margin: 0 -2rem 1.2rem -2rem;
  }
}
.vl-filters-container {
  margin-right: 0;
  .spaced {
    margin-left: 0.75rem;
    &:first-child {
      margin-left: 0rem;
    }
  }
}

.vl-checkboxes {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  margin: 1.5rem 0;
  .with-margin {
    margin-right: 1.5rem;
  }
}

.ccng-vl-header-with-subtitles {
  margin-top: 1rem;
  margin-bottom: 1.1rem;
}
