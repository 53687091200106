@import "~styles/_common.module.scss";

.item-group {
  display: flex;

  .item-start {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-left: 1px solid $medium-grey;
    border-bottom: 1px solid $medium-grey;
    border-top: 1px solid $medium-grey;

    &.item-start-expanded {
      border-bottom-left-radius: 0;
    }
  }

  .item-middle {
    border-left: 1px solid $medium-grey;
    border-bottom: 1px solid $medium-grey;
    border-top: 1px solid $medium-grey;
  }

  .item-end {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    &.item-end-expanded {
      border-bottom-right-radius: 0;
    }
  }
}
