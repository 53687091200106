// Fonts
$font-xxl: 2rem;
$font-xl: 1.5rem;
$font-lg: 1.2rem;
$font-md: 1rem;
$font-sm: 0.8rem;
$font-xs: 0.5rem;

//Gutter
$gutter: 24px;

// Left and right margins and paddings of entire page for: 
// larger screen sizes AND
// smaller screen sizes
$page-gutter: 2rem;
$page-gutter-small: 0.5rem;

// Card with icon article padding i.e. plan and actual
$card-with-icon-padding: 1.2rem;

// Card with no icon article padding i.e. plan and actual
$card-with-no-icon-padding: 0.4rem;

// Bottom margin between filters and everything below it
$filter-margin-bottom: 2rem;

// Right margin between filters
$filter-margin-right: 0.75rem;

// Table left and right paddings
$table-padding: 2.125rem;

//Border radius
$border-radius: $gutter/3;

// Border colour
$border-color: $grey;

// Lets follow this table here for setting the values of z-index
// https://www.oreilly.com/library/view/developing-web-components/9781491905685/ch04.html
$zIndexes: (
  fullPageModal: 6000001,
  sidebar: 6000000,
  header: 5000000,
  filtersSubheader: 4999999,
  dropdown: 4000000,
  optionPopup: 3999999
);

@function z($key) {
  @return map-get($zIndexes, $key);
}

// Loading shimmer
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.spinner {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}
