@import "~styles/_common.module.scss";

.breadcrumbs {

  &__children {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;

    :not(:last-child) {
      margin-right: 0.4rem;
    }

    // Hide Next Icon for last item
    :last-child {
      &.nextIcon {
        display: none;
      }
    }
  }

  .breadcrumbChild {
    display: inline;

    &:not(.selected) {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
