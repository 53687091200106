@import "~styles/_common.module.scss";

.unauthorised {
  margin: auto;
  max-width: 90vw;
  min-height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .logo {
    max-width: 30rem;
  }

  .page-title {
    margin: 0;
    margin-top: 2.75rem;
  }

  .information {
    max-width: 55vw;
    color: $silver;
  }

  .informationMY {
    color: $silver;
    font-style: italic;
    max-width: 55vw;
  }
}
