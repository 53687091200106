@import "~styles/_common.module.scss";

.icon-component {
  color: "inherit";
  &__is-rounded {
    padding: 0.5rem;
    border-radius: 5rem;
  }
}

.icon {
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.icon-font-size- {
  &small {
    font-size: $font-sm;
  }
  &medium {
    font-size: $font-md;
  }
  &large {
    font-size: $font-lg;
  }
  &extra-large {
    font-size: $font-xl;
  }
}
