@import "~styles/_common.module.scss";

.hidden {
  display: none;
}
.table-holder {
  display: flex;
  border: 1px solid $medium-grey;
  border-radius: $border-radius;
  .table {
    width: 100%;
    @include size-below(md) {
      font-size: $font-sm;
    }

    border-collapse: collapse;

    &__header {
      color: $charcoal;
      font-weight: 700;
      padding: 0.8rem;
      user-select: none;
      border-bottom: 0.01rem solid $medium-grey;
      text-transform: uppercase;
    }

    &__body {
      text-align: center;
      color: $grey;
    }
  }
}
