@import "~styles/_common.module.scss";
.ContributionTrends {
  .filter-container__left__child {
    align-items: flex-start;
    padding-right: 0 0.5rem;
    .drop-down {
      width: 100%;
    }
    .date-dropdown .drop-down {
      min-width: 7rem;
    }
    .date-dropdown > .drop-down.bordered:nth-child(2) {
      margin-left: 0;
      .drop-down__button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .date-dropdown > .drop-down.bordered:nth-child(1) {
      margin-left: 0;

      .drop-down__button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .labelCheckbox {
      margin-left: auto;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  &__legends {
    margin: 0 2.5rem 1rem 2.5rem;
  }
}

.trailchart__filter-chart {
  display: flex;
  border: 1px solid $medium-grey;
  border-radius: 0.5rem;
}

.trail-ivcr {
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  position: relative;

  .trail-chart {
    margin: 2.5rem 2.5rem 0 2.5rem;
  }

  .x-label {
    position: absolute;
    bottom: 0.9rem;
    right: 2.5rem;
    @include size-below(md){
      bottom: 2.5rem;
    }
  }
  .y-label {
    position: absolute;
    top: 6.5rem;
    left: -3.625rem;
    transform: rotate(-90deg);

    &.--has-overlay{
      opacity: 0.1;
    }
  }

  // svg {
  //   border-left: solid 2px #a5a6a8;
  //   border-bottom: solid 2px #a5a6a8;
  // }

  .contribution-page__legends{
    margin: 1rem 2.55rem;
    flex-wrap: wrap;
    @include size-below(md){
      max-width:30rem;
    }
    .box-and-label{
      @include size-below(md){
        margin-bottom: 0.5rem;
      }
    }
  }
}

.trend-group {
  .lead-circle {
    cursor: pointer;
  }

  &.has-trail .lead-circle {
    cursor: zoom-out;
  }

  &.has-trail.trail-hidden .lead-circle {
    cursor: zoom-in;
  }
}

.trends-visible .trend-group.has-trail .lead-circle {
  cursor: pointer;
}

.contributor-popup {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  left: 0;
  z-index: z(fullPageModal);
  &.show-popup {
    display: flex;
  }
  &__mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 0;
    cursor: pointer;
  }
  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 60vw;
    &__details {
      display: flex;
      flex-direction: column;
      .fixed-accordion-table {
        background: $white;
        .child-row {
          .table__body {
            &:empty{
              &:after {
                content:"-";
                display:inline-block;
              }
            }
          }
        }
      }
      &__table {
        display: flex;
        flex-direction: row-reverse;
        background: $white;
        justify-content: space-between;
        color: $medium-grey;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border-bottom: 1px solid $medium-grey;

        h2 {
          margin: 1.5rem;
          color: $black;
          font-weight: 500;
        }

        .icon {
          padding: 0.5rem;
          font-size: 2rem;
          z-index: 11;
        }
      }
    }
  }
}

.contribution-trends-filters-container {
  .spaced {
    margin: 0.25rem 0;
    margin-right: $filter-margin-right;
  }
  .contribution-trends-filter-checkboxes{
    min-height: 4rem;
    display: flex;
    align-items: center;
  }
}

.contribution-trends-filters {
  transition: all 200ms ease-in-out;
  margin-bottom: $filter-margin-bottom;
  background: transparent;
  padding:0.4rem 0;
  &.sticky {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4rem 1rem 0px;
    border-radius: 0 0 0.2rem 0.2rem;
    background: white;
    // -2 because parent has a margin right and left of 2.
    // It's a hack. I had to. Sorry :/
    // If you have a better solution, please go ahead.
    // I was under pressure from other "chefs"
    padding: 0.4rem 2rem;
    margin: 0 -2rem 1.2rem -2rem;
    @include size-below(md) {
      margin: 0 -0.5rem;
      padding: 0 0.5rem;
    }
  }
}


.contribution-trends-header-with-subtitles{
  margin-top:1rem;
  margin-bottom:1.1rem;
}