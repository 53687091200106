@import "~styles/_common.module.scss";

// Following DLS design guidelines.
.loader {
  border: 0.3125rem solid $medium-grey;
  border-top: 0.3125rem solid $teal;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
