@import "~styles/_common.module.scss";

.ValueLevers {
  &__chart {
    padding-bottom: 5rem;
  }

  &__tables {
    padding: 1rem 0 5rem 0;
    &.no-border {
      padding: 0 0 1rem 0;
      border: none;
    }

    &__heading {
      display: flex;
      margin-bottom: 1rem;
      padding-top: 2rem;
      border-top: 1px solid rgb(204, 205, 207);
      h2,
      h3 {
        margin: 0;
      }
      .costBreakdownTypeDropdown {
        margin-left: auto;
        min-width: 18rem;
        max-height: 4rem;
      }
    }

    .fixed-cost {
      margin-bottom: 2rem;
    }

    .TableAccordion__table__body__row__data > .name-attr {
      text-transform: capitalize;
    }

    .sortable-table
      > .table
      > tbody
      > tr
      > td
      > .diffpercent
      > .indicator
      > .icon {
      padding-right: 0.4rem;
    }
  }
}

.value-levers-filters {
  transition: all 200ms ease-in-out;
  margin-bottom: $filter-margin-bottom;
  background: transparent;
  padding:0.4rem 0;
  &.sticky {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4rem 1rem 0px;
    border-radius: 0 0 0.2rem 0.2rem;
    background: white;
    // -2 because parent has a margin right and left of 2.
    padding: 0.4rem 2rem;
    margin: 0 -2rem 1.2rem -2rem;
    @include size-below(md) {
      margin: 0 -0.5rem;
      padding: 0 0.5rem;
    }
  }
}
.value-levers-header-with-subtitles {
  margin-top: 1rem;
  margin-bottom: 1.1rem;
}

.vl-filters-container {
  margin-right: 0;
  .spaced {
    margin: 0.25rem 0;
    margin-right: $filter-margin-right;
  }
}
