@import "~styles/_common.module.scss";

.indicator {
  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    color: inherit;
  }
  [class^="icon-"],
  [class*=" icon-"] {
    line-height: inherit;
  }

  &.variance {
    &:after {
      content: "%";
    }
    &.enclosed {
      margin: auto;
      &:before {
        content: "(";
      }
      &:after {
        content: "%)";
      }
      &.neutral {
        margin-left: 0.2rem;
      }
    }
  }
  // If trending down
  &.down,
  .down & {
    display: inline;
    color: $tomato;
  }

  // If trending up
  &.up,
  .up & {
    display: inline;
    color: $teal;
    [class^="icon-"]:before,
    [class*=" icon-"]:before {
      transform: rotate(180deg);
      display: inline-block;
    }
  }

  &.down.opposite,
  .down.opposite > & {
    color: $teal;
  }

  &.up.opposite,
  .up.opposite > & {
    color: $tomato;
  }
  &.down.neutral,
  &.up.neutral {
    color: $body-color;
  }

  &.down.opp {
    display: inline;
    color: $tomato;
    [class^="icon-"]:before,
    [class*=" icon-"]:before {
      transform: rotate(180deg);
      display: inline-block;
    }
  }
  &.up.opp {
    [class^="icon-"]:before,
    [class*=" icon-"]:before {
      transform: rotate(360deg);
      display: inline-block;
    }
    display: inline;
    color: $teal;
  }
}
.neutral {
  &.down .indicator,
  &.up .indicator,
  &.down .trend-amt,
  &.up .trend-amt {
    color: $body-color;
  }
}
