@import "~styles/_common.module.scss";

.rte table.market-value-unit {
  th,
  td {
    border: none;
    border-bottom: 1px solid $border-color;
    &:first-child {
      width: 60%;
      text-align: left;
    }
  }
  th {
    background-color: $white;
    color: $body-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 2px solid $border-color;
  }
  tr:last-child td {
    border: none;
  }
}
