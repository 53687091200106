@import "~styles/_common.module.scss";

.normal-button {
  &.padding-0 {
    padding: 0.8125rem 0;
  }
  &.padding-2 {
    padding: 0.8125rem 2rem;
  }
}

.text-button {
  padding: 0.8125rem 0;
  background: transparent;
}

.normal-button,
.text-button {
  border: none;
  transition: filter 200ms ease-in;
  will-change: filter;
  cursor: pointer;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:active {
    filter: brightness(90%) !important;
  }

  &:hover {
    filter: brightness(110%);
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.rounded {
    border-radius: 20rem;
  }

  &.disabled {
    pointer-events: none;
    cursor: none;
  }

  .icon-color {
    margin-right: 0.2rem;
    margin-top: 0.1rem;
    font-size: $font-sm;
    &:before {
      color: inherit;
    }
  }
}
