.chart {
  position: relative;
  display: flex;
  max-width: 100%;
  flex-wrap: nowrap;
  align-items: stretch;
}

.chart--no-scroll {
  display: inline-block;
  vertical-align: top;
}

.chart--no-scroll svg {
  max-width: 100%;
}

.chart--no-scroll:first-child {
  margin-bottom: 1em;
}

@media (min-width: 480px) {
  .chart--no-scroll:first-child {
    margin-right: 1em;
  }
}

.chart--responsive {
  position: relative;
  overflow: hidden;
  height: 0;
}

.chart--responsive svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chart__main {
  display: block;
}

.chart__shadow {
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: 100%;
}

.chart__shadow::before,
.chart__shadow::after {
  position: absolute;
  z-index: 1990;
  top: 0;
  bottom: 0;
  width: 30px;
  content: "";
  pointer-events: none;
  transition: opacity 0.3s;
}

.chart__shadow--start::before,
.chart__shadow--end::after {
  opacity: 0;
}

.chart__shadow--disable::before,
.chart__shadow--disable::after {
  display: none;
}

.chart__shadow::before {
  left: 0;
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.chart__shadow::after {
  right: 0;
  background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
}

.chart__scroll {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.chart__scroll__indicator {
  position: absolute;
  z-index: 20;
  padding: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chart__scroll__indicator::before {
  display: block;
  width: 7px;
  height: 7px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  content: "";
}

.chart__scroll__indicator--start {
  left: 10px;
}

.chart__scroll__indicator--start::before {
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  transform: rotate3d(0, 0, 1, -135deg);
}

.chart__scroll__indicator--end {
  right: 10px;
}

.chart__scroll__indicator--end::before {
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.chart__axis--y {
  flex-shrink: 0;
}

.chart__popup {
  padding: 0.5em 1em;
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  transform: translate3d(-50%, 0, 0);
}

.ie-9 .chart {
  font-size: 0;
  white-space: nowrap;
}

.ie-9 .chart::after {
  display: table;
  clear: both;
  content: "";
}

.ie-9 .chart__y-axis,
.ie-9 .chart__shadow,
.ie-10 .chart__y-axis,
.ie-10 .chart__shadow {
  display: inline-block;
  vertical-align: top;
}

.ie-9 .chart__y-axis {
  width: 60px;
}

.ie-9 .chart__shadow,
.ie-10 .chart__shadow {
  width: calc(100% - 60px);
}

.ie-9 .multi-chart .chart__shadow,
.ie-10 .multi-chart .chart__shadow {
  width: calc(100% - 120px);
}
