@import "~styles/_common.module.scss";

.maintenance {
  margin: auto;
  max-width: 90vw;
  min-height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 2rem;

  .logo {
    max-width: 30rem;
  }

  .pageTitle {
    margin: 0;
    margin-top: 2.75rem;
  }

  .information {
    max-width: 55vw;
    color: $silver;
    white-space: normal;
  }

  .informationMY {
    color: $silver;
    font-style: italic;
    max-width: 55vw;
    white-space: normal;
  }
  .browser-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      margin: 3rem;
      @include size-below(md) {
        margin: 1rem;
      }
      img {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}
