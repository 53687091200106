@import "~styles/_common.module.scss";

.sortable-table.table-holder {
  display: flex;
  border: 1px solid $medium-grey;
  border-radius: $border-radius;
  @include size-below(md) {
    overflow-x: auto;
  }
  .table {
    width: 100%;
    table-layout: fixed;
    @include size-below(md) {
      font-size: $font-sm;
    }

    border-collapse: collapse;

    &__header {
      color: $charcoal;
      font-weight: bold;
      font-size: $font-md;
      white-space: nowrap;
      @include size-below(md) {
        font-size: $font-sm;
      }
      padding: 1.125rem 1rem;
      user-select: none;
      border-bottom: 2px solid $medium-grey;
      text-transform: uppercase;
      text-align: right;
      width: 15%;

      &:first-child {
        text-align: left;
        padding-left: $table-padding;
        width: 40%;
      }
      &:last-child {
        padding-right: $table-padding;
      }

      i {
        color: $charcoal;
        margin-left: 0.5rem;
        margin-bottom: 0.1rem;
        cursor: pointer;
      }

      &.diff-header,
      &.diff-percent {
        text-align: right;
      }

      &.no {
        i {
          display: none;
        }
      }
    }

    &__body {
      text-align: right;
      color: $grey;
      padding: 1rem;
      font-size: $font-md;
      font-weight: 500;
      width: 15%;

      @include size-below(md) {
        font-size: $font-md;
      }
      &:first-child {
        width: 40%;
        text-align: left;
        padding-left: $table-padding;
      }
      &:last-child {
        padding-right: $table-padding;
      }
      .diffUSD,
      .diffpercent {
        font-weight: 500;
        padding-right: 0.8rem;
      }
      .minus {
        color: $tomato;
        .indicator.up > i {
          transform: rotate(180deg);
        }
        &.opp {
          color: $teal;
        }
      }
      .plus {
        color: $teal;
        &.opp {
          color: $tomato;
          .indicator.down > i {
            transform: rotate(180deg);
          }
        }
      }
      &.neutral {
        color: $body-color;
      }
      .currency-wrapper {
        display: inline-flex;
      }
    }

    tbody > tr:not(:last-child) {
      border-bottom: 1px solid $medium-grey;
    }
  }
}
