@import "~styles/_common.module.scss";

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  font-size: $font-sm;
  padding: 0.5rem;
  border-right: 0.3rem solid transparent;

  i.nav-item-icon {
    font-size: $font-sm;
    padding: 0.5rem;
    color: $white;
  }

  .link-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $white;

    label {
      font-weight: lighter;
      cursor: pointer;
      font-size: $font-xs;
      text-align: center;
    }
  }

  &.selected,
  &:hover {
    text-decoration: none;
    border-right: 0.3rem solid $white;
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    > i.NavItemIcon:before {
      color: inherit;
    }
  }

  > i.nav-item-icon {
    font-size: $font-xl;
    @include size-below(md) {
      font-size: $font-lg;
    }
    &:before {
      color: inherit;
    }
  }
}
