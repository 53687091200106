@import "~styles/_common.module.scss";

.TrendAnalysis {
  padding:0 $page-gutter $page-gutter $page-gutter;
  @include size-below(md) {
    padding: 0 $page-gutter-small $page-gutter $page-gutter-small;
  }

  .TrendAnalysis-header-with-subtitles {
    margin-top: 1rem;
    margin-bottom: 1.1rem;
  }

  &-filters {
    transition: all 200ms ease-in-out;
    margin-bottom: $filter-margin-bottom;
    background: transparent;
    padding:0.4rem 0;
    &.sticky {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4rem 1rem 0px;
      border-radius: 0 0 0.2rem 0.2rem;
      background: white;
      // -2 because parent has a margin right and left of 2.
      padding: 0.4rem 2rem;
      margin: 0 -2rem 1.2rem -2rem;
    }
  }

  &__breadcrumbs {
    margin-bottom: 1rem;
  }

  &__chart {
    display: flex;
    border: 1px solid $medium-grey;
    border-radius: 0.5rem;
    flex-direction: column;
    .label-text {
      font-size: $font-sm;
      margin: 1.5rem 2.25rem;
      color: $grey;
    }
    .stackedbarchart-ivcr {
      // overflow: scroll;
      border: 0.2rem solid transparent;
    }
  }

  &__legends {
    align-items: flex-end;
    margin: 0.8rem 0;
  }
  &-legend {
    .label-text {
      @include font-size($base-font-size * 0.75);
      color: $grey;
      font-style: italic;
      flex-basis: 100%;
      width: 0;
      margin: 0.5rem 0 0;
    }
  }
}

.TrendAnalysis-filter-component {
  margin-right: 0;

  .spaced {
    margin: 0.25rem 0;
    margin-right: $filter-margin-right;
  }
}
