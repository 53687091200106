@import "~styles/_common.module.scss";

.dropdown {
  display: flex;
  flex-direction: column;
  font-size: $font-md;
  width: 8.75rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;

  &.bordered {
    border: none;
    .dropdown__button {
      border: 1px solid $medium-grey;
    }
  }

  &.rounded{
    .dropdown__button{
      border-radius: 0.25rem;
    }
  }
  &__button {
    padding: 0.6rem;
    outline: none;
    color: $grey;
    background: white;
    margin: 0;
    border: 0;
    height: 100%;
    width: 100%;
    white-space: pre;
    max-height: 3.625rem;

    &.menuExpanded {
      border-radius: 0.25rem 0.25rem 0 0;
      transition: border-radius 200ms ease-in-out;
    }

    &:not(.disabled) {
      cursor: pointer;
    }

    &.disabled {
      background-color: $pale-grey;
      color: $charcoal;
    }

    &__heading {
      display: flex;
      text-transform: uppercase;
    }

    &__headingBottom {
      display: flex;
      margin-top: 0.4rem;
      justify-content: space-between;
      align-items: center;

      &__label {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__options {
    color: $grey;
    position: relative;
    width: 100%;
  

  &__menuParent {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__menu {
    transition: all 200ms ease-in-out;
    position: absolute;
    min-width: 100%;
    max-width: 14.375rem;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
    top: -0.05rem;
    z-index: z(dropdown);

    &.hidden {
      max-height: 0;
      background-color: transparent;
      box-shadow: none;
      border: none;
    }

    &:not(.hidden) {
      max-height: 15rem;
      box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
      border: 1px solid $medium-grey;
      background-color: white;
    }
  }
}

}

