@import "~styles/_common.module.scss";

.wrap-container {
  display: flex;
  align-items: flex-start;
  .childs {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
  }
  .expand-button {
    min-width: 7rem;
    max-width: 7rem;
  }
  .inverse-arrow {
    transform: rotate(180deg);
  }
}
