@import "~styles/_common.module";


.TableAccordion{
    display: flex;
    justify-content: center;
    border: 1px solid $medium-grey;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background-color: $white;

    .--align-center{
        text-align: center;
    }

    .--align-right{
        text-align: right;
    }

    .--align-left{
        text-align: left;
    }

    &__table{
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
  
        &__head{
            font-weight: bold;
            color: $charcoal;
            border-bottom: 2px solid $medium-grey;

            &__row{
                font-weight:400;
                border-bottom: 1px solid #cccdcf;
                &__head{
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 1rem;
                    padding: 1.125rem 1rem;
                    user-select: none;
                    
                    &.--sortable{
                        cursor: pointer;
                        &:hover{
                            filter: brightness(80%);
                            background: rgba($color: #cccdcf, $alpha: .1);
                        }
                    }

                    &.--sorted{
                        text-decoration: underline;
                    }

                    &:nth-child(1){
                        padding-left: $table-padding;
                    }

                    &__sort{
                        margin-left: .2rem;
                        margin-bottom: .1rem;
                    &.sort-asc{
                        color: $teal;
                        &:before {
                            color: inherit;
                        }  
                    } 

                    &.sort-desc{
                        color: $teal;
                        &:before {
                            color: inherit;
                        }
                    }
                }
            }
        }
    }
        &__body{
            &__row{
                border-bottom: 1px solid #cccdcf;

                &.expanded{
                    border-bottom: 4px solid $teal;
                    td{
                        font-weight: bold;
                    }
                }
                &.expandable{
                    &:hover{
                        cursor: pointer;
                        background-color: #f8f8f8;
                    }
                }
                &.child-row {
                    background-color: $pale-grey;
                    border-bottom: 1px solid $medium-grey;
                    &:hover {
                      background-color: $pale-grey;
                    }
                    .table__body > .name-attr {
                      padding-left: 3rem;
                    }
                    &.first-child {
                      border-top: 4px solid $teal;
                    }
                    &.sub-child{
                      .table__body > .name-attr {
                        padding-left: 4.5rem;
                        &:before {
                          content:"-";
                          display:inline-block;
                          position:absolute;
                          transform: translateX(-1rem);
                        }
                      }
                    }
                    &:last-child {
                      border-bottom: none;
                    }
                    span {
                      display: inline-block;
                    }

                    .TableAccordion__table__body__row__data{
                        &:nth-child(1){
                            display: block;
                            margin-left: 3rem;
                        }
                    }
                  }
                &__data{
                    border: none;
                    padding: 1.2rem;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.1rem;
                    width: auto;
                    user-select: none;
                    color: #666769;
                    
                    &:nth-child(1){
                        padding-left: $table-padding;
                    }

                    .--expanded-icon{
                        color: teal;
                        :before{
                            color: inherit;
                        }
                    }
                    &__loader {
                        border: .2rem solid #f3f3f3; /* Light grey */
                        border-top: .2rem solid $teal; /* Blue */
                        border-radius: 50%;
                        width: 1rem;
                        height: 1rem;
                        display: inline-block;
                        animation: spin .5s linear infinite;
                      }
                      
                      @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                      }
                }
            }
        }
    }

}