@import "~styles/_common.module.scss";

.header-div {
  display: flex;
  color: $grey;
  align-items: center;

  i {
    cursor: pointer;
  }
  &__info {
    position: relative;
  }
  .info-icon.active::before {
    color: $teal;
  }
  .info-icon.active {
    color: $teal;
  }
  .info-popup {
    z-index: z(optionPopup);
    position: absolute;
    display: none;
    left: 0;
    top: -4rem;
    border: 1px solid $medium-grey;
    border-radius: 0.25rem;
    padding: 0.4rem 0.2rem;
    background: $white;
    min-width: 12rem;
    max-width: 15rem;
    min-height: 3.5rem;
    box-shadow: 9px 8px 12px 1px rgba(0, 0, 0, 0.1);
    justify-content: space-around;
    p {
      white-space: normal;
      margin: 1rem 0 1rem 1rem;
      text-align: left;
    }
  }
  .info-popup.show-popup {
    display: flex;
  }
  &.subheader {
    display: block;
    height: auto;
    p {
      font-size: 0.875rem;
      margin: 0 1rem 0.5rem 0;
      font-weight: normal;
      color: $silver;
      line-height: 1.4;
    }
    h2 {
      margin-bottom: 0.4375rem;
      margin-top: 1.25rem;
    }
  }
}
