@import "~styles/_common.module.scss";

h2.card-title--header {
  color: $silver;
  font-weight: 900;
  text-transform: uppercase;
  white-space: nowrap;
  justify-content: center;
  @include font-size(12, 1);
}
