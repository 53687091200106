@import "~styles/_common.module";

.overlay-popup {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  left: 0;
  z-index: z(fullPageModal);
  &.show-popup {
    display: flex;
  }
  &__mask {
    background: rgba(255, 255, 255, 0.6);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 0;
    cursor: pointer;
  }
  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 25rem;
    display: flex;
    flex-direction: column;
    background: $white;
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
    max-height: 60vh;
    border-radius: 0.5rem;
    border: 1px solid $medium-grey;
    overflow: hidden;
    &__header {
      padding: 1.2rem 1.4rem 1.2rem 1.4rem;
      border-bottom: 2px solid $medium-grey;
      position: sticky;
      top: 0;
      background: white;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      h3 {
        margin: 0;
        font-size: 1rem;
      }
      .icon {
        cursor: pointer;
      }
    }

    &__section {
      display: flex;
      flex-direction: column;
      background: $white;
      justify-content: space-between;
      max-height: 60vh;
      overflow: scroll;
      .icon {
        padding: 0.5rem;
        font-size: 2rem;
        z-index: 11;
      }
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;

      tbody {
        tr {
          &:last-child {
            td {
              border-bottom: 1px solid transparent;
            }
          }
          td {
            padding: 1rem 0;
            color: $grey;
            border-bottom: 1px solid $medium-grey;
            font-size: 1.2rem;
            font-size: 1rem;

            &:first-child {
              padding-left: 1.4rem;
              text-align: start;
            }
            &:last-child {
              padding-right: 1.4rem;
              text-align: end;
            }
          }
        }
      }
    }
  }
}
