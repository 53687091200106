@import "~styles/_common.module.scss";

.fixed-accordion-table {
  overflow: hidden;
  position: relative;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  .table-holder {
    display: block;
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    overflow: auto;
  }
  .table {
    @include size-below(md) {
      font-size: $font-md;
    }
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    border: none;

    &__header {
      color: $black;
      font-weight: 700;
      padding: 0.8rem 1rem;
      user-select: none;
      border-bottom: 2px solid $pale-grey;
      text-transform: uppercase;
      background: #fff;
      white-space: nowrap;
      vertical-align: top;

      text-align: right;
      &:first-child {
        text-align: left;
        padding-left: $table-padding;
      }
      i {
        color: $charcoal;
      }
      p {
        margin: 0;
        white-space: nowrap;
      }
    }

    .expanded > .table__body {
      &.fixed-column {
        color: $black;
      }
    }

    .table__body.fixed-column {
      color: $grey;
    }
    &__body {
      text-align: right;
      border-bottom: 1px solid $medium-grey;
      white-space: nowrap;
      vertical-align: top;
      background-color: $white;
      font-weight: 500;
      padding: 0.8rem 1rem 0.8rem 1.5rem;
      &:first-child {
        text-align: left;
      }
      .positiveDeviation {
        color: $teal;
      }

      &.fixed-column {
        background: transparent;
      }
      i {
        margin: 0 0.5rem 0.3rem 0.5rem;
        font-size: $font-md;
      }
    }
    tr {
      font-weight: 400;
      &.expanded {
        td {
          border-bottom: 0;
        }
      }
      &.child-row {
        display: none;
        &.expanded {
          display: table-row;
          font-weight: 400;
        }
        .table__body {
          background-color: $pale-grey;
          &:first-child {
            padding-left: 3.5rem;
            color: $grey;
          }
        }
        &.first-child {
          td {
            border-top: 3px solid $teal;
          }
        }
        &:last-child {
          border-bottom: none;
        }
        span {
          display: inline-block;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      .right-align {
        text-align: right;
        .indicator.up > i {
          font-size: $font-lg;
          vertical-align: top;
        }
        .indicator.down > i {
          font-size: $font-lg;
        }
      }
      p {
        margin: 0;
        white-space: nowrap;
      }
    }
  }
  .clone {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    th,
    td {
      visibility: hidden;
      border-bottom: 1px solid $medium-grey;
    }
    th {
      border-bottom: 1px solid $medium-grey;
    }
    tbody td.fixed-column,
    thead th.fixed-column {
      visibility: visible;
      background: white;
    }
  }
}
