@import "~styles/_common.module.scss";
$navWidth: 5rem;

.sidebarContentLayout {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__appNav {
    width: $navWidth;
    z-index: 9999999;
    background: $teal;
    box-shadow: 3px 0 38px -15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: sticky;
    top: 0%;

    
    &__navList {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 5rem;
      &__logout {
        margin-top: auto;
        position: sticky;
        bottom: 0;
      }
    }
  }

  &__bodyContent {
    flex-grow: 1;
    // overflow: auto;
    width: calc(100vw - 5rem);
  }
}

.expanded-nav-menu-enter {
  opacity: 0;
  transform: translate(-100%, 0);
}

.expanded-nav-menu-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 250ms ease-out, transform 250ms ease-out;
}

.expanded-nav-menu-exit-active {
  opacity: 0;
  transform: translate(-100%, 0);
  transition: opacity 100ms ease-in, transform 100ms ease-in;
}