@import "~styles/_common.module";

.subNavigationWrapperBreadcrumbs {
  margin: 1.5rem 0 0 $page-gutter;
  @include size-below(md) {
    margin: 1.5rem $page-gutter-small 0 $page-gutter-small;
  }
  .link-item {
    color: inherit;
  }
}
