@import "~styles/_common.module.scss";

.squareTab {
  font-family: $primary-font;
  display: inline-block;
  user-select: none;
  padding: 0.8rem 1.2rem;
  cursor: pointer;
  font-weight: bold;
  font-size: $font-sm;
  transition: all 230ms ease-in-out;
  color: $charcoal;
  background-color: $white;
  border-top: 1px solid $medium-grey;
  border-left: 1px solid $medium-grey;
  border-bottom: 0;

  &:last-child {
    border-right: 1px solid $medium-grey;
  }

  @include size-below(sm) {
    color: $grey;
    padding: 0.8rem;
    margin: 0rem;
    background-color: $white;
    box-sizing: content-box;
    border:1px solid $medium-grey;
  }

  @include size-above(sm) {
    &:hover {
      background-color: $light-aqua;
      transition: all 230ms ease-in-out;
    }

    &:active {
      background-color: $dark-aqua;
      color: white;
    }
  }

  &.active {
    color: $white;
    background-color: $teal;
    cursor: default;

    span {
      flex-grow: 1;
    }

    @include size-below(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $grey;
      background-color: $white;

      i {
        display: block;
        position: relative;
        right: 0;
        top: 0.76px;
      }
    }
  }

  i {
    display: none;
  }
}
