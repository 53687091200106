@import "~styles/_common.module.scss";

.box-and-label {
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
  &__left {
    &.line {
      width: 1rem;
      height: 0.05rem;
      margin-right: 0.4rem;

      &.teal {
        background-color: $teal;
        border: 1px solid $teal;
      }
      &.lightaqua {
        background-color: $light-aqua;
        border: 1px solid $light-aqua;
      }
      &.sand {
        background-color: $sand;
        border: 1px solid $sand;
      }
      &.twilight {
        background-color: $twilight;
        border: 1px solid $twilight;
      }
      &.indigo {
        background-color: $indigo;
        border: 1px solid $indigo;
      }
      &.lightsand {
        background-color: $light-sand;
        border: 1px solid $light-sand;
      }
      &.white {
        background-color: $white;
        border: 1px solid $medium-grey;
      }
    }
    &.box {
      width: 1rem;
      height: 0.8rem;
      margin-right: 0.4rem;
      border-radius: 2px;

      &.teal {
        background-color: $teal;
        border: 1px solid $teal;
      }
      &.lightaqua {
        background-color: $light-aqua;
        border: 1px solid $light-aqua;
      }
      &.darkaqua{
        background-color: $dark-aqua;
        border: 1px solid $dark-aqua;
      }
      &.sand {
        background-color: $sand;
        border: 1px solid $sand;
      }
      &.twilight {
        background-color: $twilight;
        border: 1px solid $twilight;
      }
      &.indigo {
        background-color: $indigo;
        border: 1px solid $indigo;
      }
      &.lightsand {
        background-color: $light-sand;
        border: 1px solid $light-sand;
      }
      &.cranberry {
        background-color: $cranberry;
        border: 1px solid $cranberry;
      }
      &.cobalt {
        background-color: $cobalt;
        border: 1px solid $cobalt;
      }
      &.white {
        border: 1px solid $medium-grey;
        background: repeating-linear-gradient(
          45deg,
          $medium-grey,
          $medium-grey 0.5px,
          $white 2px,
          $white 4px
        );
      }
    }
  }

  &__right {
    color: $grey;
  }
}
