@import "~styles/_common.module.scss";

$card-unitvalue-fontsize: 26;
$card-trend-fontsize: 12;

.cmp-card {
  background-color: $white;
  color: $grey;
  justify-content: center;
  text-align: center;

  &.bordered {
    padding: 3px;
    @include size-above(md) {
      padding: 12px;
    }
    &.no-padding {
      padding: 0;
    }
  }

  &.grey-tile {
    background: $pale-grey;
    color: #fff;

    .cmp-card {
      background: $pale-grey;
    }
  }

  h1.card-title--header {
    color: $grey;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-size(20, 1);

    @include size-below(md) {
      @include font-size(15, 1);
    }

    .heading-icon {
      @include font-size(30, 1);
      margin-right: 12px/4;
      @include size-below(md) {
        @include font-size(18, 1);
      }
    }
    &.spinner {
      height: $font-lg;
    }
  }

  p {
    margin: 0;
  }

  .card-body {
    width: 100%;
  }

  .dashboard-headerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    .unit-value {
      .currency {
        @include font-size(14, 1);
      }
      .amount {
        @include font-size(46, 1);
        line-height: 2.3rem;
      }
    }
    .unit {
      @include font-size(18, 1);
      font-weight: 500;
      color: $grey;
    }
  }

  .trend-and-unit {
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    height: 4rem;
    align-items: center;
    justify-content: flex-end;
    .unit-value {
      padding-top: ($card-trend-fontsize + ($gutter/1px)/4) + px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      @include font-size($card-unitvalue-fontsize, 1);
      font-weight: 700;
      color: $grey;
      @include size-below(md) {
        @include font-size($card-unitvalue-fontsize - 8, 1);
      }
      .currency {
        @include font-size(12, 1);
        font-weight: 300;
        padding-right: 0.2rem;
        margin-top: 0; /*0.15rem;*/
      }
    }
    .trend {
      margin-top: 0.2rem;
      @include font-size($card-trend-fontsize, 1);
      @include size-below(md) {
        @include font-size($card-trend-fontsize - 2, 1);
      }
      .indicator.up {
        > i {
          vertical-align: text-top;
        }
      }
    }
  }

  dt,
  dd {
    margin-bottom: 12px/2;
    @include size-between(sm, md) {
      margin-bottom: 12px/4;
    }
  }
  //Organisms
  .unit-and-trend {
    display: flex;
    flex-direction: column;
    .variance-body {
      display: flex;
      width: auto;
      // Removed: Was causing issues with alignment of icons.
      // height: 4rem;
      padding-top: ($card-trend-fontsize + ($gutter/1px)/4) + px;
      .variance-body-indicator-positions {
        display: flex;
        flex-direction: column;
      }
      &.spinner-height {
        padding-bottom: 4.6rem; //This is just to align the spinner height to be the same
      }
    }
    .trend-amt {
      font-weight: bold;
      @include font-size(26, 1);
      @include size-below(md) {
        @include font-size(18, 1);
      }
    }
    .indicator {
      @include size-below(md) {
        @include font-size(15, 1);
      }
      &.overview-chevron-icon {
        display: flex;
        align-items: center;
        font-size: 3rem;
        margin-right: 0.5rem;
        @include size-below(md) {
          margin-right: 0.1rem;
        }
        i {
          &:before {
            font-size: 1.75rem;
            @include size-below(md) {
              @include font-size(18, 1);
            }
            margin-top: -0.5rem;
          }
        }
      }
    }
    &.spinner {
      height: 1rem;
      width: 4rem;
      display: inline-table;
    }
  }
  .card-group {
    &.border-top {
      border-top: 1px solid $pale-grey;
    }
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    .cmp-card.item {
      align-items: center;
      width: 50%;
      flex: 0 1 auto;
      border-right: 1px solid $pale-grey;
      padding: 0;
      align-self: flex-start;

      @include size-above(md) {
        padding: $card-with-no-icon-padding 0;
      }
      margin: 0 1px;

      &:last-child {
        border-right: none;
        &.spinner {
          margin-left: 1rem;
        }
      }
    }
  }
}

.with-icon {
  &.selected {
    position: relative;
    border-top: 0.45rem solid $teal;
    background-color: $off-white;

    .cmp-card.cmp-card.item {
      background-color: $off-white;
    }
  }
  &:hover {
    position: relative;
    background-color: $off-white;

    .cmp-card.cmp-card.item {
      background-color: $off-white;
    }
  }

  &.selected:after {
    position: absolute;
    top: 100%;
    left: 63%;
    margin-left: -26%;
    content: "";
    width: 0;
    height: 0;
    border-top: solid 23px $off-white;
    border-left: solid 40px transparent;
    border-right: solid 40px transparent;

    @include size-below(md) {
      left: 55%;
      margin-left: -28%;
      border-left: solid 30px transparent;
      border-right: solid 30px transparent;
    }
  }
  .header-info-pop-up {
    justify-content: center;
  }
}
