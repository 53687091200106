@import "~styles/_common.module.scss";

header.appHeader {
  display: flex;
  align-items: stretch;
  height: 5rem;
  will-change: "height";
  transition: height 150ms ease-in-out;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1rem 0px;
  background-color: white;

  &.stickyBar {
    position: sticky;
    top: 0;
    z-index: z(header);
  }

  .pageTitle {
    padding: 0.5rem 2rem;
    margin: auto 0;
    margin-right: auto;
  }

  .pageContent {
    display: flex;
    align-items: center;
  }

  .pageMetaContent {
    border-left: 2px solid $silver;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    color: $silver;
  }

  .logo {
    opacity: 1;
    border-right: 1px solid $medium-grey;
    padding: 0.8rem;
    width: 6rem;
    height: 5rem;
    will-change: "opacity, width, height, padding, margin";
    transition: opacity 100ms, width 100ms, height 100ms, padding 100ms,
      margin 100ms ease-out;
    margin-left: 1rem;
    padding-right: 1.8rem;
  }

  &.minimized {
    border-bottom: 0.2rem solid $teal;
    height: 3rem;
    width: 100%;
    box-shadow: none;
    .logo {
      border-right: 0;
      opacity: 0;
      padding: 0;
      width: 0rem;
      height: 0rem;
      margin: 0;
    }
  }
  .pointer {
    cursor: pointer;
  }
}
