@import "~styles/_common.module.scss";

.trend-amt {
  &.down,
  .down > & {
    color: $tomato;
  }

  &.up,
  .up > & {
    color: $teal;
  }

  &.down.opposite,
  .down.opposite > & {
    color: $teal;
  }

  &.up.opposite,
  .up.opposite > & {
    color: $tomato;
  }

  &.neutral,
  .neutral > & {
    color: $body-color;
  }
}
