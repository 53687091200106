@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?167fqu");
  src: url("/assets//fonts/icomoon.eot?167fqu#iefix")
      format("embedded-opentype"),
    url("/assets//fonts/icomoon.ttf?167fqu") format("truetype"),
    url("/assets//fonts/icomoon.woff?167fqu") format("woff"),
    url("/assets//fonts/icomoon.svg?167fqu#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// ==========================================================================
// Rules
// ==========================================================================

// From icomoon generated package

.icon-delete:before {
  content: "\e900";
  color: #666869;
}
.icon-edit:before {
  content: "\e901";
  color: #666869;
}
.icon-flow-chart:before {
  content: "\e902";
  color: #666869;
}
.icon-opti-simu-navi:before {
  content: "\e903";
  color: #666869;
}
.icon-table:before {
  content: "\e904";
  color: #666869;
}
.icon-view:before {
  content: "\e905";
  color: #666869;
}
.icon-arrow:before {
  content: "\e906";
  color: #fff;
}
.icon-asset:before {
  content: "\e907";
  color: #646464;
}
.icon-buildup:before {
  content: "\e908";
  color: #006865;
}
.icon-chevron:before {
  content: "\e909";
  color: $silver;
}
.icon-physical:before {
  content: "\e910";
  color: #006865;
}
.icon-psc:before {
  content: "\e911";
  color: #666769;
}
.icon-slug-catcher:before {
  content: "\e912";
  color: #666769;
}
.icon-trend:before {
  content: "\e913";
  color: #006865;
}
.icon-user:before {
  content: "\e914";
  color: #006865;
}
.icon-value-lever:before {
  content: "\e915";
  color: #006865;
}

.icon-filter:before {
  content: "\e916";
  color: #666769;
}
.icon-home:before {
  content: "\e917";
  color: #006865;
}
.icon-contribution:before {
  content: "\e90a";
  color: #006865;
}
.icon-exit:before {
  content: "\e90b";
  color: #006865;
}
.icon-flng:before {
  content: "\e90c";
  color: #646464;
}
.icon-integrated:before {
  content: "\e90d";
  color: #666769;
}
.icon-lng-ship:before {
  content: "\e90e";
  color: #666769;
}
.icon-mlng:before {
  content: "\e90f";
  color: #646464;
}

.icon-close:before {
  content: "\e918";
  color: #cccdcf;
}
.icon-info:before {
  content: "\e919";
  color: $silver;
}
.icon-info:hover:before {
  color: $teal;
}
.icon-sort_down:before {
  content: "\e91a";
}
.icon-sort_up:before {
  content: "\e91b";
  color: #666769;
}
.icon-sort_updown:before {
  content: "\e91c";
  color: #cccdcf;
}
.icon-table-arrow_down:before {
  content: "\e91d";
  // color: #cccdcf;
}
.icon-table-arrow_right:before {
  content: "\e91e";
  color: #cccdcf;
}

.icon-check:before {
  content: "\f00c";
}
