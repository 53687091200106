@import "~styles/_common.module.scss";

.stackedbarchart-ivcr {
  width: 100%;
  // Added a fixed height because the loading bar was not showing for some reason.
  padding: 0 2rem;
  position: relative;
  min-height: 30rem;
  &.--no-data-overlay{
    background-color: rgba($pale-grey,.32);
  }

  &__loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    background: white;
  }

  &__no-data {
    width: 20%;
    height: auto;
    max-width: 10rem;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    z-index: 1;
  }

  .stacked-bar-chart {
    height: 100%;

    &.--has-overlay {
      opacity: 0.1;
      height: 100%;

      .chart__shadow{
        &:before,
        &:after{
          background-color: transparent;
          background-image: none;
        }
      }
    }

    &:not(.--has-overlay) {
      opacity: 1;
    }
  }

  .legend-container-wrap {
    position: relative;
  }

  .y-label {
    position: absolute;
    top: 15rem;
    left: 1.5rem;
    transform-origin: left;
    transform: rotate(270deg);
    direction: rtl;
    width: 13rem;

    &.--has-overlay {
      opacity: 0.1;
    }
  }
}

.unit-margin__filter-chart .stackedbarchart-ivcr {
  overflow-y: hidden;
}
