@import "~styles/_common.module.scss";

.dynamic-table {
  th.table__header {
    @include size-above(md) {
      &:first-child {
        width: 40%;
      }
      width: 20%;
    }
    width: 25%;
  }
}
