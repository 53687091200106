$teal: #00a19c;
$dark-aqua: #006865;
$light-aqua: #9ad8d2;

$black: #000000;
$charcoal: #333436;
$grey: #666769;
$silver: #999a9c;
$pale-grey: #ecedef;
$medium-grey: #cccdcf;
$white: #ffffff;
$off-white: #f8f8f8; // in dls this is pale grey

$sand: #ffbe4f;
$light-sand: #f5c981;

$tomato: #e75552;
$cranberry: #9b151d;

$twilight: #58478d;
$cobalt: #1c2c73;
$indigo: #2f1a48;
$light-purple: #cfcade;

$body-color: $grey;

:export {
  Teal: $teal;
  DarkAqua: $dark-aqua;
  LightAqua: $light-aqua;
  Black: $black;
  Charcoal: $charcoal;
  Grey: $grey;
  Silver: $silver;
  White: $white;
  PaleGrey: $pale-grey;
  MediumGrey: $medium-grey;
  Sand: $sand;
  Tomato: $tomato;
  Cranberry: $cranberry;
  Twilight: $twilight;
  Indigo: $indigo;
  Cobalt: $cobalt;
  LightPurple: $light-purple;
}
