@import "~styles/_common.module.scss";

.cost-margin {
  box-sizing: border-box;
  padding: 0 $page-gutter $page-gutter $page-gutter;
  @include size-below(md) {
    padding: 0 $page-gutter-small $page-gutter $page-gutter-small;
  }

  .cost-margin-header-with-subtitles {
    margin-top: 1rem;
    margin-bottom: 1.1rem;
  }

  &-filters {
    transition: all 200ms ease-in-out;
    margin-bottom: $filter-margin-bottom;
    background: transparent;
    padding: 0.4rem 0;
    &.sticky {
      margin: 0 -2rem 1.2rem -2rem; // -2 because parent has a margin right and left of 2.
      padding: 0.4rem 2rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4rem 1rem 0px;
      border-radius: 0 0 0.2rem 0.2rem;
      background: white;
      @include size-below(md) {
        margin: 0rem -0.5rem 0rem -0.5rem;
        padding: 0rem 0.5rem 0rem 0.5rem;
      }
    }
  }

  &__breadcrumbs {
    margin-bottom: 1rem;
  }

  &__filter-chart {
    display: flex;

    &__filter-container {
      .filter-container__left__child {
        display: flex;
        flex-direction: column;
        align-items: center;

        .drop-down,
        .date-dropdown > .drop-down.adjust-border {
          min-width: 12rem;
          margin: 0 1rem;
        }

        .date-dropdown > .drop-down {
          min-width: unset;
        }

        .date-dropdown > .drop-down.bordered:nth-child(2) {
          margin-left: 0;
          .drop-down__button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        .date-dropdown > .drop-down.bordered:nth-child(1) {
          margin-left: 0;

          .drop-down__button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .labelCheckbox {
          margin: 1rem 1rem 5rem auto;
        }
      }
    }
  }
  .cost-margin-buildup-chart-parent {
    flex-grow: 1;
  }
  .buildup-chart-legends {
    padding: 0 1rem;
    margin: 3rem 0;
  }
}

#lng__cost__margin__market-index {
  display: none;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $silver;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: $grey;
  background: $white;
  table {
    padding: 1rem;
    display: table;
    width: calc(100% - 1rem);
    th {
      font-weight: 300;
      font-size: $font-lg;
    }
    tbody {
      tr {
        font-weight: bold;
        font-size: $font-lg;
        td {
          padding: 0 1rem;
          text-align: right;
          font-size: $font-md;
          span {
            display: inline-block;
            &:last-child {
              min-width: 2rem;
            }
          }
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
}
