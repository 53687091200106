@import "~styles/_common.module.scss";

.navExpandedMenu {
  position: fixed;
  top: 0;
  left: 5rem;
  height: 100vh;
  background-color: $pale-grey;
  z-index: z(sidebar);
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease-in;
  width: 20.5rem;
  // white-space: pre;

  &__navExpandedItems {
    list-style: none;
    margin: 1.5rem 0 0 0;
    padding: 0;
    &.navItem {
      box-sizing: border-box;
    }

    &__navExpandedItem {
      display: flex;
      align-items: center;
      flex-direction: row;
      color: $grey;
      font-size: 1rem;
      margin: 0 0 1.25rem 0;
      height: 2.87rem;
      padding: 0;
      cursor: pointer;
      a,
      a:hover,
      a:visited {
        text-decoration: none;
        color: inherit;
        align-items: center;
        display: flex;
        height: inherit;
        width: 100%;
        padding: 0 1.6rem;
      }
      i.icon_color {
        font-size: 1.25rem;
        color: $grey;
        margin-right: 1.5rem;
        &:before {
          color: inherit;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &.selected {
        background-color: rgba(0, 0, 0, 0.15);
        color: $white;
        i {
          color: $white;
        }
      }
    }
  }

  &__title {
    width: 100%;
    font-size: 1.125rem;
    font-weight: bold;
    color: $grey;
    box-sizing: border-box;
    padding: 0 1.625rem;
    height: calc(5rem + 1px);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $medium-grey;
    line-height: 1.2;
    &__expandedClose {
      cursor: pointer;
      &:before {
        color: inherit;
        cursor: pointer;
      }
    }
  }
}
