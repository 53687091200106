@import "~styles/_common.module.scss";

.tabsTableContainer {
  &__header {
    display: flex;
    user-select: none;
    flex-direction: row;
    padding-bottom: 0.1rem;

    @include size-below(sm) {
      flex-direction: column;
      padding: 0;
      background-color: $grey;
    }

    > * {
      &:last-child {
        border-top-right-radius: $border-radius;
      }
      &:first-child {
        border-top-left-radius: $border-radius;
      }
    }

    &.tabs-expanded {
      @include size-below(sm) {
        .tab-table-unselected {
          display: inherit;
        }

        .tabTable-selected.active {
          cursor: pointer;
          background-color: $teal;
        }
      }
    }

    &:not(.tabs-expanded) {
      @include size-below(sm) {
        .tab-table-unselected {
          display: none;
        }

        .tabTable-selected.active {
          border-color: transparent;
          cursor: pointer;
        }
      }
    }
  }

  &__tab-panel {
    display: flex;
    flex-direction: column;
    border: 1px solid $medium-grey;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    margin-top: -0.1rem;
    padding: 2rem 1rem;
    &--content-hidden {
      display: none;
    }
  }
}
