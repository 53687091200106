@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/Museo/MuseoSans_300.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/Museo/MuseoSans_500.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/Museo/MuseoSans_700.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/Museo/MuseoSans_900.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSans";
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/Museo/MuseoSans_300_italic.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSans";
  font-style: italic;
  font-weight: 700;
  src: url("/assets/fonts/Museo/MuseoSans_700_italic.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSans";
  font-style: italic;
  font-weight: 900;
  src: url("/assets/fonts/Museo/MuseoSans_900_italic.otf") format("opentype");
}

// ==========================================================================
// Defaults
// ==========================================================================
$primary-font: "MuseoSans";

@mixin font-primary() {
  font-family: $primary-font,Arial, sans-serif;
}

// ==========================================================================
// Mixins
// ==========================================================================
$base-font-size: 16 !default;

@mixin font-size($size-in-px, $line-height: 1.5) {
  font-size: $size-in-px / $base-font-size * 1rem;
}
