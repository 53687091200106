@import "~styles/_common.module.scss";

.card-title--footer {
  @include font-size(12, 1);
  font-weight: 300;
  color: $silver;
  width: 100%;
  .unit {
    display: block;
    position: relative;
    min-height: 12px;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
}
